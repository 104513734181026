import { domReady } from '../../../halocom/ts/Lib';
import Carousel from '../inputs/Carousel';

// import
import type {} from '../../../types/interfaces';

domReady((): void => {
	const front: Record<string, any> = {
		carousel: new Carousel('.carousel'),
	};
	document.front = document.front ?? {};
	document.front = { ...document.front, ...front };
});
