/**
 * @returns void
 */
export function domReady(callback: () => void): void {
    let initiated = false;

    window.addEventListener(
        'DOMContentLoaded',
        (): void => {
            if (!initiated) {
                initiated = true;
                callback();
            }
        },
        { passive: true }
    );

    if (
        document.readyState === 'interactive' ||
        document.readyState === 'complete'
    ) {
        if (!initiated) {
            initiated = true;
            callback();
        }
    }
}

/**
 * Pour récupérer la position d'un élément parmis ses frères
 * @param  element - l'élément HTML dont on souhaite connaitre l'index
 * @returns number
 */
export function getIndex(element: HTMLElement): number {
    if (!(element instanceof HTMLElement)) {
        return -1;
    }

    let index = 0;
    let sibling = element.previousElementSibling;
    while (sibling != null) {
        index++;
        sibling = sibling.previousElementSibling;
    }

    return index;
}

/**
 * @param  prefix - L'ID aléatoire généré sera préfixé par la valeur de ce paramètre
 * @returns string - (exemple: PREFIX-123456)
 */
export function randomID(prefix: string = 'id-'): string {
    return prefix + Math.round(Math.random() * 1000000);
}

/**
 * Pour attribuer la hauteur la + haute à une liste d'éléments HTML sélectionnés
 * @param  elements - Une NodeListOf<HTMLElement> à traiter
 * @returns void
 */
export function sameHeight(elements: NodeListOf<HTMLElement>): void {
    let hauteurMax = 0;

    // Recherche de la hauteur la plus haute
    elements.forEach((value: Node): void => {
        const element = value as HTMLElement;

        // Reset height
        element.style.height = 'auto';

        const hauteur = element.offsetHeight;

        if (hauteur > hauteurMax) {
            hauteurMax = hauteur;
        }
    });

    // Attribution de la hauteur max à tous les éléments
    elements.forEach((value: Node): void => {
        const element = value as HTMLElement;

        element.style.height = hauteurMax.toString() + 'px';
    });
}

/**
 * Fonction utile pour passer des données en ajax via la method POST
 * @param  data - example \{param1: 'test', param2 = 5\}
 */
export function stringifyParams(data: Record<string, string>): string {
    const params = new URLSearchParams(data);
    return params.toString();
}

/**
 * Pour envelopper un HTMLElement avec un autre HTMLElement
 * @param  wrapped - l'élement à envelopper
 * @param  wrapper - l'élément enveloppeur
 * @returns void
 */
export function wrap(wrapped: HTMLElement, wrapper: HTMLElement | null): void {
    if (wrapped == null) {
        return;
    }
    wrapper = wrapper ?? document.createElement('div');
    // insert wrapper Before wrapped
    const parent = wrapped.parentNode as HTMLElement;
    if (parent != null) {
        parent.insertBefore(wrapper, wrapped);
        wrapper.appendChild(wrapped);
    }
}

/**
 * Pour envelopper l'innerHTML d'un HTMLElement avec un autre HTMLElement
 * @param  parent - L'élément HTML parent dont le contenu se fera envelopper
 * @param  wrapper - L'élement HTML qui enveloppera le contenu de l'élement parent
 * @returns void
 */
export function wrapInner(parent: HTMLElement, wrapper: HTMLElement): void {
    const firstContent = parent.innerHTML;
    wrapper.innerHTML = firstContent;
    parent.innerHTML = wrapper.outerHTML;
}

export default null;
