export default class Carousel {
	elements: NodeListOf<HTMLElement>;
	carousel = document.querySelector('.carousel') as HTMLUListElement;
	carousel_UL = document.querySelector('.carousel > ul') as HTMLUListElement;
	currentIndex = 0;

	constructor(public selector: string) {
		this.init();
	}

	init() {
		this.onclick();
		this.onresize();
		this.updateCarousel();
	}

	onclick() {
		const leftArrow = this.carousel.querySelector('.left-arrow') as HTMLSpanElement;
		const rightArrow = this.carousel.querySelector('.right-arrow') as HTMLSpanElement;

		leftArrow.addEventListener('click', () => {
			if (this.currentIndex > 0) {
				this.currentIndex--;
				this.updateCarousel();
			}
		});

		rightArrow.addEventListener('click', () => {
			if (this.currentIndex < this.carousel_UL.children.length - 3) {
				this.currentIndex++;
				this.updateCarousel();
			}
		});

		leftArrow.addEventListener('touchstart', () => {
			if (this.currentIndex > 0) {
				this.currentIndex--;
				this.updateCarousel();
			}
		});

		rightArrow.addEventListener('touchstart', () => {
			if (this.currentIndex < this.carousel_UL.children.length - 3) {
				this.currentIndex++;
				this.updateCarousel();
			}
		});
	}

	onresize() {
		window.addEventListener('resize', this.updateCarousel);
	}

	updateCarousel() {
		if (this.carousel) {
			const width = this.carousel.querySelector('li')?.offsetWidth || 0;
			this.carousel_UL.style.transform = `translateX(${-this.currentIndex * width}px)`;

			const leftArrow = this.carousel.querySelector('.left-arrow') as HTMLSpanElement;
			const rightArrow = this.carousel.querySelector('.right-arrow') as HTMLSpanElement;
			leftArrow.style.height = this.carousel_UL.offsetHeight + 'px';
			rightArrow.style.height = this.carousel_UL.offsetHeight + 'px';
		}
	}
}
